import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { useState } from "react";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
import { HelpPopup } from "../../../components/widgets/help-popup";
import { Popup } from "../../../components/widgets/popup";
export const BeSolPopup = ({
  children
}) => {
  const [isVisible, setVisible] = useState(false);
  return <>
        <span onClick={() => setVisible(true)}>
            {children}
        </span>
        {isVisible === true && <Popup title="You Are Leaving TuringTrader.com" onCancel={() => setVisible(false)} mdxType="Popup">
                <p>
                    The following content is provided by <em>Bertram Solutions LLC</em>,
                    a <em>Registered Investment Adviser</em> in the State of Washington.
                </p>
                <p>
                    <ButtonPrimary text="continue to bertram solutions" to="https://www.bertram.solutions/investment-advice/trading-service/" mdxType="ButtonPrimary" />
                </p>
            </Popup>}
    </>;
};
export const _frontmatter = {};
const layoutProps = {
  BeSolPopup,
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{props.pageContext.frontmatter.title}</h1>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` caters to `}<em parentName="p">{`DIY investors`}</em>{`. But there are many good reasons why you might want professional assistance:`}</p>
    <p><strong parentName="p">{`Commitment of Time.`}</strong>{` While we try to keep portfolio maintenance as simple as possible, it is still an ongoing commitment. And maybe your situation does not allow you to make this commitment right now. With a managed account, you can be sure your portfolio is always rebalanced on time.`}</p>
    <p><strong parentName="p">{`Quality of Life.`}</strong>{` Life is busy, and there is never enough time to do the things you love. With a managed account, you can sit back and enjoy the convenience of a professional handling your portfolio for you.`}</p>
    <p><strong parentName="p">{`Peace of Mind.`}</strong>{` Trading with `}<em parentName="p">{`TuringTrader.com`}</em>{` is not complicated. And still, there is always room for potential errors. With a managed account, you have the peace of mind that a seasoned professional trades your account.`}</p>
    <br />
    <BeSolPopup mdxType="BeSolPopup">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/41445a55ece80396f3e6c8dddffa35ea/32ac3/Banner-Ad-1b.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "12.333333333333332%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAiUlEQVQI1x3Muw6CMABAUf7/a1x1MrpIglEXpKXFlqLlXTAhDA7XhOGsJzrfPcdbQyJmdoeEQhvC+KXrAv24kL88We7IlKOwNbJ4b5TxCF0hdUX++iCUo/KBSLmA7xba6UccXykve0Ia0z9ODM6gbMtT2i3RtiYVBqHdlhRlQyotVR3op5VhXvkDjxqQuPRtZKgAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Bertram Solutions: ",
              "title": "Bertram Solutions: ",
              "src": "/static/41445a55ece80396f3e6c8dddffa35ea/c1b63/Banner-Ad-1b.png",
              "srcSet": ["/static/41445a55ece80396f3e6c8dddffa35ea/5a46d/Banner-Ad-1b.png 300w", "/static/41445a55ece80396f3e6c8dddffa35ea/0a47e/Banner-Ad-1b.png 600w", "/static/41445a55ece80396f3e6c8dddffa35ea/c1b63/Banner-Ad-1b.png 1200w", "/static/41445a55ece80396f3e6c8dddffa35ea/32ac3/Banner-Ad-1b.png 1249w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </BeSolPopup>
    <br />
    <p>{`We have licensed our portfolios to `}<em parentName="p">{`Bertram Solutions`}</em>{`, an investment adviser specializing in algorithmic trading, for those members looking for assistance. `}<em parentName="p">{`Bertram Solutions`}</em>{`' proprietary infrastructure allows them to run customized portfolios in each client's account with daily rebalancing.`}</p>
    <br />
    <BeSolPopup mdxType="BeSolPopup">
      <ButtonPrimary text="Visit Bertram Solutions" mdxType="ButtonPrimary" />
    </BeSolPopup>
    <br />
    <br />
    <br />
    <h2>{`Frequently Asked Questions`}</h2>
    <ul>
      <li>
        <HelpPopup title="What is a managed account?" mdxType="HelpPopup"> 
          <p>{`A managed account is an account that is owned by you and held in your name but managed by a financial professional. Because the account is held in your name, you have complete control over it. However, the financial professional oversees the account and the trading activity within it.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="How does this work?" mdxType="HelpPopup">
          <p>{`First, you and your financial professional agree on an investment policy for the account. Further, you grant your financial professional `}<em parentName="p">{`discretionary authority`}</em>{` to place orders in your account - but only within the bounds of the agreed-upon investment policy.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="Where is my account held?" mdxType="HelpPopup">
          <p><em parentName="p">{`Bertram Solutions`}</em>{` uses `}<em parentName="p">{`Interactive Brokers`}</em>{` as its custodian. This adds another layer of protection for you because the financial professional never takes custody of your hard-earned funds.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="How do I know what's going on?" mdxType="HelpPopup">
          <p>{`Managed accounts are fully transparent. You can log into your account and review the activity at any time. And the custodian sends you a monthly statement with all the details, including the fees.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="How much does it cost?" mdxType="HelpPopup">
          <p><em parentName="p">{`Bertram Solutions`}</em>{` charges an annual fee of 1% of assets under management in your account. This fee is calculated daily and deducted monthly in arrears and does not include brokerage commissions, transaction fees, or other related costs and expenses that the client normally incurs.`}</p>
          <p>{`As an added bonus, `}<em parentName="p">{`Bertram Solutions`}</em>{`'s clients receive complimentary access to TuringTrader's `}<em parentName="p">{`Premium`}</em>{` membership.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="What is the minimum account size?" mdxType="HelpPopup">
          <p>{`The minimum account size for a managed account with `}<em parentName="p">{`Bertram Solutions`}</em>{` is $100,000.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="Can I still trade myself?" mdxType="HelpPopup">
          <p>{`Absolutely! You decide which accounts you want your adviser to manage and which accounts you want to trade yourself. And, of course, the management fee only applies to your managed accounts.`}</p>
        </HelpPopup></li>
      <li><HelpPopup title="How do I get started? " mdxType="HelpPopup">
          <p>{`Simply contact `}<em parentName="p">{`Bertram Solutions`}</em>{` at `}<a href="mailto:info@bertram.solutions"><a parentName="p" {...{
                "href": "mailto:info@bertram.solutions"
              }}>{`info@bertram.solutions`}</a></a>{` or call `}<a href="tel:425-616-2755">{`(425) 616-2755`}</a>{` to schedule a free consultation.`}</p>
        </HelpPopup></li>
    </ul>
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      